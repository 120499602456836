import 'primeflex/primeflex.css';

import App from './App.vue';

import { createApp } from 'vue';
import * as Sentry from "@sentry/vue";

const app = createApp(App);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    /* integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ], */
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import router from './router';
app.use(router);

import PrimeVue from 'primevue/config';
import Aura from '@primeuix/themes/aura';
import PrimeVueEsLocale from './primevue-es';

app.use(PrimeVue, {
    locale: PrimeVueEsLocale,
    theme: {
        preset: Aura,
    },
});

import ToastService from 'primevue/toastservice';
app.use(ToastService);

import ConfirmationService from 'primevue/confirmationservice';
app.use(ConfirmationService);

app.mount('#app');