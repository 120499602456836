<script setup lang="ts">
</script>

<template>
  <div class="anonimo-encab flex jc-center">
    <img src="/img/cooprg21_oficinavirtual_horizontal.svg" class="anonimo-encab-logo" alt="Oficina Virtual" />
  </div>
</template>

<style>
.anonimo-encab {
  min-height: 0.2ph;
  text-align: center;
}

.anonimo-encab h1 {
  color: rgb(31, 31, 31);
  font-size: 32px;
}

.anonimo-encab-logo {
  margin: 10px auto 16px auto;
  width: 280px;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 1));
}
</style>