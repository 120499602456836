export enum MutationTypes {
  SET_SIDENAV_DISABLED = 'SET_SIDENAV_DISABLED',
  SET_TOKEN = 'SET_TOKEN',
  LOGOUT = 'LOGOUT',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  SET_USUARIO = 'SET_USUARIO',
  DELETE_USUARIO = 'DELETE_USUARIO',
  SET_SUMINISTROS = 'SET_SUMINISTROS',
  SET_REQUERIMIENTOS_CATEGORIAS = 'SET_REQUERIMIENTOS_CATEGORIAS',
  SET_TARJETAS = 'SET_TARJETAS',
  ADD_TARJETA = 'ADD_TARJETA',
}