import { ArchivoEstado } from './ArchivoEstado';

export default class ArchivoFactura {
  constructor(
    public id: number,
    public nombre: string,
    public estado: ArchivoEstado,
    public created: Date,
    public updated: Date,
    public tamano: number,
    public progreso: number,
    public renglonesImportados: number,
  ) {
  }

  static fromData(data: any): ArchivoFactura {
    return new ArchivoFactura(
      data.id,
      data.nombre,
      data.estado,
      data.created,
      data.updated,
      data.tamano,
      data.progreso,
      data.renglonesImportados,
    );
  }

  static fromDataArray(data: any): ArchivoFactura[] {
    const res = new Array<ArchivoFactura>();
    data.forEach(function (el: any) {
      res.push(ArchivoFactura.fromData(el));
    });
    return res;
  }

  get Iri(): string {
    return '/api/archivo_facturas/' + this.id;
  }

  public static estadoNombre(estado: number): string {
    switch (estado) {
      case ArchivoEstado.NUEVO:
        return 'nuevo';
      case ArchivoEstado.CONFIRMADO:
        return 'confirmado';
      case ArchivoEstado.PROCESANDO:
        return 'procesando';
      case ArchivoEstado.TERMINADO:
        return 'terminado';
      case ArchivoEstado.ANULADO:
        return 'anulado';
      case ArchivoEstado.ERROR:
        return 'error';
      default:
        return '???';
    }
  }

  get estadoNombre(): string {
    return ArchivoFactura.estadoNombre(this.estado);
  }
}