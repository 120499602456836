import type { OtpEstados } from './OtpEstados';
import UsuarioPreferencias from './UsuarioPreferencias';

export default class Usuario {
  constructor(
    public id: number,
    public email: string,
    public telefono: string,
    public documento: string,
    public cuit: string,
    public nombre: string,
    public apellido: string,
    public razonSocial: string,
    public suministro: number,
    public roles: string[],
    public aceptarLegal: boolean,
    public recibirPapel: boolean,
    public identidadValidada: boolean,
    public emailValidado: boolean,
    public telefonoValidado: Date | undefined,
    public nombreVisible: string,
    public preferencias: UsuarioPreferencias | undefined,
    public otpEstado: OtpEstados | undefined,
  ) {
  }

  static fromData(data: any): Usuario {
    return new Usuario(
      data.id,
      data.email,
      data.telefono,
      data.documento,
      data.cuit,
      data.nombre,
      data.apellido,
      data.razonSocial,
      data.suministro,
      data.roles,
      data.aceptarLegal,
      data.recibirPapel,
      data.identidadValidada,
      data.emailValidado,
      (data.telefonoValidadoFecha == undefined || data.telefonoValidadoFecha == null) ? undefined : new Date(data.telefonoValidadoFecha),
      data.nombreVisible,
      data.preferencias ? UsuarioPreferencias.fromData(data.preferencias) : undefined,
      data.otpEstado,
    );
  }

  static fromDataArray(data: any): Usuario[] {
    const res = new Array<Usuario>();
    data.forEach(function (el: any) {
      res.push(Usuario.fromData(el));
    });
    return res;
  }

  public get telefonoValidadoReciente(): boolean {
    return this.telefonoValidado != undefined && this.telefonoValidado > new Date(new Date().getTime() - 86400000 * 3650);  // 10 años
  }

  public hasRole(role: string): boolean {
    try {
      return this.roles.includes(role);
    } catch {
      return false;
    }
  }

  get Iri(): string {
    return '/api/usuarios/' + this.id;
  }
}
