import { RequerimientoEstados } from './RequerimientoEstados';
import RequerimientoSeguimiento from './RequerimientoSeguimiento';
import ArchivoAdjunto from './ArchivoAdjunto';
import Usuario from './Usuario';
import Suministro from './Suministro';
import type RequerimientoInterface from './RequerimientoInterface';

export default class Requerimiento implements RequerimientoInterface {
  constructor(
    public id: number,
    public descripcion: string,
    public estado: RequerimientoEstados,
    public categoria: any,
    public created: Date,
    public updated: Date,
    public usuario: Usuario,
    public seguimientos: RequerimientoSeguimiento[],
    public adjuntos: ArchivoAdjunto[],
    public suministro: Suministro | string | number | undefined,
    public encargado: Usuario | undefined,
    public novedadesUsuario: boolean,
    public novedadesEncargado: boolean,
  ) {
  }

  static fromData(data: any): Requerimiento {
    return new Requerimiento(
      data.id,
      data.descripcion,
      data.estado,
      data.categoria,
      data.created,
      data.updated,
      Usuario.fromData(data.usuario),
      RequerimientoSeguimiento.fromDataArray(data.seguimientos),
      ArchivoAdjunto.fromDataArray(data.adjuntos),
      data.suministro == undefined ? undefined : (data.suministro instanceof String ? data.suministro.replace('/api/requerimientos/', '') : data.suministro),
      data.encargado,
      data.novedadesUsuario,
      data.novedadesEncargado,
    );
  }

  static fromDataArray(data: any): Requerimiento[] {
    const res = new Array<Requerimiento>();
    data.forEach(function (el: any) {
      res.push(Requerimiento.fromData(el));
    });
    return res;
  }

  get Iri(): string {
    return '/api/requerimientos/' + this.id;
  }

  public static estadoNombre(estado: number): string {
    switch (estado) {
      case RequerimientoEstados.NUEVO:
        return 'pendiente';
      case RequerimientoEstados.ABIERTO:
        return 'iniciado';
      case RequerimientoEstados.ESPERANDO_USUARIO:
        return 'esperando al usuario';
      case RequerimientoEstados.TERMINADO:
        return 'terminado';
      case RequerimientoEstados.ANULADO:
        return 'anulado';
      default:
        return '???';
    }
  }

  get estadoNombre(): string {
    return Requerimiento.estadoNombre(this.estado);
  }
}