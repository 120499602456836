export default class Indicador {
  constructor(
    public id: number,
    public nombre: string,
    public periodo: string,
    public valor: number,
  ) {
  }

  static fromData(data: any): Indicador {
    return new Indicador(
      data.id,
      data.nombre,
      data.periodo,
      data.valor,
    );
  }

  static fromDataArray(data: any): Indicador[] {
    const res = new Array<Indicador>();
    data.forEach(function (el: any) {
      res.push(Indicador.fromData(el));
    });
    return res;
  }
}