import Usuario from './Usuario';

export default class Mensaje {
  constructor(
    public id: number,
    public usuario: Usuario | undefined,
    public para: string,
    public tipo: number,
    public prioridad: number,
    public estado: number,
    public asunto: string,
    public tipoContenido: string,
    public tokenUsado: boolean,
    public created: Date | undefined,
    public updated: Date | undefined,
  ) {
  }

  isProcesado(): boolean {
    return this.estado == 1;
  }

  static fromData(data: any): Mensaje {
    return new Mensaje(
      data.numericId ?? data.id,
      data.usuario ? Usuario.fromData(data.usuario) : undefined,
      data.para,
      data.tipo,
      data.prioridad,
      data.estado,
      data.asunto,
      data.tipoContenido,
      data.tokenUsado,
      data.created,
      data.updated,
    );
  }

  static fromDataArray(data: any): Mensaje[] {
    const res = new Array<Mensaje>();
    data.forEach(function (el: any) {
      res.push(Mensaje.fromData(el));
    });
    return res;
  }
}