import { ArchivoEstado } from './ArchivoEstado';

export default class ArchivoSubida {
  constructor(
    public id: number,
    public nombre: string,
    public estado: ArchivoEstado,
    public created: Date,
    public updated: Date,
    public progreso: number,
  ) {
  }

  static fromData(data: any): ArchivoSubida {
    return new ArchivoSubida(
      data.id,
      data.nombre,
      data.estado,
      data.created,
      data.updated,
      data.progreso,
    );
  }

  static fromDataArray(data: any): ArchivoSubida[] {
    const res = new Array<ArchivoSubida>();
    data.forEach(function (el: any) {
      res.push(ArchivoSubida.fromData(el));
    });
    return res;
  }

  get Iri(): string {
    return '/api/archivo_subidas/' + this.id;
  }

  public static estadoNombre(estado: number): string {
    switch (estado) {
      case ArchivoEstado.NUEVO:
        return 'nuevo';
      case ArchivoEstado.CONFIRMADO:
        return 'confirmado';
      case ArchivoEstado.PROCESANDO:
        return 'procesando';
      case ArchivoEstado.TERMINADO:
        return 'terminado';
      case ArchivoEstado.ANULADO:
        return 'anulado';
      case ArchivoEstado.ERROR:
        return 'error';
      default:
        return '???';
    }
  }

  get estadoNombre(): string {
    return ArchivoSubida.estadoNombre(this.estado);
  }
}