import { PagoEstados } from '@/model/PagoEstados';
import { PagoMetodos } from '@/model/PagoMetodos';
import Factura from './Factura';
import Tarjeta from './Tarjeta';
import Usuario from './Usuario';

export default class Pago {
  constructor(
    public id: number,
    public metodo: PagoMetodos,
    public facturas: Factura[],
    public tarjeta: Tarjeta | undefined,
    public estado: PagoEstados,
    public tarjetaCvc: string,
    public created: Date | undefined,
    public facturasTotal: number,
    public motivoRechazo: string | undefined,
    public usuario: Usuario | undefined,
    public origen: string | undefined,
    public extra: string | undefined,
    public tiempos?: any,
  ) {
  }

  isProcesado(): boolean {
    return this.estado == 1;
  }

  static fromData(data: any): Pago {
    return new Pago(
      data.numericId ?? data.id,
      data.metodo,
      Factura.fromDataArray(data.facturas),
      data.tarjeta ? Tarjeta.fromData(data.tarjeta) : undefined,
      data.estado,
      data.tarjetaCvc,
      data.created,
      data.facturasTotal,
      data.motivoRechazo,
      Usuario.fromData(data.usuario),
      data.origen,
      data.extra,
      data.tiempos,
    );
  }

  static fromDataArray(data: any): Pago[] {
    const res = new Array<Pago>();
    data.forEach(function (el: any) {
      res.push(Pago.fromData(el));
    });
    return res;
  }
}