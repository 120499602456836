import type { GetterTree } from 'vuex';
import { State } from './state';
import ApiToken from '@/model/ApiToken';
import Usuario from '@/model/Usuario';
import Suministro from '@/model/Suministro';
import Tarjeta from '@/model/Tarjeta';
import Pago from '@/model/Pago';

export type Getters = {
  isLoggedIn(state: State): boolean;
  hasRole(state: State, role: string): boolean;
  isAdmin(state: State): boolean;
  isConfirmado(state: State): boolean;
  isValidado(state: State): boolean;
  apiToken(state: State): ApiToken | undefined;
  usuario(state: State): Usuario | undefined;
  suministros(state: State): Suministro[];
  suministroSeleccionado(state: State): Suministro | undefined;
  tarjetas(state: State): Tarjeta[];
  pago(state: State): Pago | undefined;
}

export const getters: GetterTree<State, State> & Getters = {
  isLoggedIn: (state: State) => {
    return state && state.apiToken !== undefined;
  },
  hasRole: (state: State, role: string) => {
    return getters.isLoggedIn(state) && state.usuario !== undefined && state.usuario.hasRole(role);
  },
  isAdmin: (state: State) => {
    return getters.hasRole(state, 'ROLE_ADMIN')
  },
  isConfirmado: (state: State) => {
    return getters.hasRole(state, 'ROLE_CONFIRMADO') && state.usuario !== undefined && state.usuario.nombre.length >= 3;
  },
  isValidado: (state: State) => {
    return getters.hasRole(state, 'ROLE_VALIDADO');
  },
  apiToken: (state: State) => {
    return state.apiToken;
  },
  usuario: (state: State) => {
    return state.usuario;
  },
  suministros: (state: State) => {
    return state.suministros;
  },
  suministroSeleccionado: (state: State) => {
    return state.suministroSeleccionado;
  },
  tarjetas: (state: State) => {
    return state.tarjetas;
  },
  pago: (state: State) => {
    return state.pago;
  }
}
