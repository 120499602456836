import { createRouter, createWebHistory } from 'vue-router';
import { store } from '@/store';

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: () => import('@/views/PaginaInicio.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/restablecer',
    name: 'reset_password',
    component: () => import('../views/Restablecer.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/autologin',
    name: 'autologin',
    component: () => import('../views/AutoLogin.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/Logout.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/registro',
    name: 'registro',
    component: () => import('../views/Registro.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../views/usuario/Perfil.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/2fa',
    name: '2fa',
    component: () => import('../views/usuario/Otp.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/preferencias',
    name: 'preferencias',
    component: () => import('../views/usuario/Preferencias.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/eliminar_cuenta',
    name: 'eliminar_cuenta',
    component: () => import('../views/EliminarCuenta.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/validar/identidad',
    name: 'validar_identidad',
    component: () => import('../components/ValidarIdentidad.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/validar/telefono',
    name: 'validar_telefono',
    component: () => import('../components/ValidarTelefono.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tarjetas',
    name: 'tarjetas',
    component: () => import('../views/usuario/Tarjetas.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tarjetas/agregar',
    name: 'tarjetas_agregar',
    component: () => import('../views/usuario/TarjetasAgregar.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/requerimientos/cat/:catid',
    name: 'requerimientos',
    component: () => import('../views/usuario/Requerimientos.vue'),
    props: true,
    meta: { requiresAuth: true },
  },
  /* {
    path: '/requerimientos/:sustantivo',
    name: 'requerimientos',
    component: () => import('../views/usuario/Requerimientos.vue'),
    props: true,
    meta: { requiresAuth: true },
  }, */
  {
    path: '/requerimientos/ver/:id',
    name: 'requerimientos_ver',
    component: () => import('../views/usuario/RequerimientoVer.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/requerimientos/agregar/:catid',
    name: 'requerimientos_agregar',
    component: () => import('../views/usuario/RequerimientosAgregar.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/suministros',
    name: 'suministros',
    component: () => import('../views/usuario/Suministros.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/suministros/agregar',
    name: 'suministros_agregar',
    component: () => import('../views/usuario/SuministrosAgregar.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/facturas',
    name: 'facturas',
    component: () => import('../views/usuario/Facturas.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/facturas/pagar',
    name: 'facturas_pagar',
    component: () => import('../views/usuario/FacturasPagar.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/facturas/pagos',
    name: 'pagos',
    component: () => import('../views/usuario/Pagos.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/facturas/pagos/:id',
    name: 'pagos_ver',
    component: () => import('../views/usuario/PagoVer.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/consumos/:suministro?',
    name: 'consumos',
    component: () => import('../views/usuario/Consumos.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/usuarios',
    name: 'admin_usuarios',
    component: () => import('../views/admin/Usuarios.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/suministro_mails',
    name: 'admin_suministro_mails',
    component: () => import('../views/admin/SuministroMails.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/suministro_mails/agregar',
    name: 'admin_suministro_mails_agregar',
    component: () => import('../views/admin/SuministroMailsAgregar.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/usuarios/:id',
    name: 'admin_usuarios_ver',
    component: () => import('../views/admin/UsuarioVer.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/usuarios/:id/editar',
    name: 'admin_usuarios_editar',
    component: () => import('../views/admin/UsuarioEditar.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/archivos_facturas',
    name: 'admin_archivos_facturas',
    component: () => import('../views/admin/ArchivosFacturas.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/archivos_subidas',
    name: 'admin_archivos_subidas',
    component: () => import('../views/admin/ArchivosSubidas.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/cortes',
    name: 'admin_cortes',
    component: () => import('../views/admin/Cortes.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/facturas/pagos',
    name: 'admin_pagos',
    component: () => import('../views/admin/Pagos.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/facturas/pagos/:id',
    name: 'admin_pagos_ver',
    component: () => import('../views/admin/PagoVer.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/mensajes',
    name: 'admin_mensajes',
    component: () => import('../views/admin/Mensajes.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/requerimientos',
    name: 'admin_requerimientos',
    component: () => import('../views/admin/Requerimientos.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/requerimientos/ver/:id',
    name: 'admin_requerimientos_ver',
    component: () => import('../views/admin/RequerimientoVer.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/indicadores',
    name: 'consumoindicadores',
    component: () => import('../views/admin/Indicadores.vue'),
    meta: { requiresAuth: true },
  },
  //{ path: '*', component: NotFoundComponent },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  //history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.getters.isLoggedIn == false) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
