import type { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import type { Mutations } from './mutations';
import { ActionTypes } from './action-types';
import { MutationTypes } from './mutation-types';
import { store } from '.';
import { api } from '@/api';
import PersistentState from '@/model/PersistentState';
import ApiToken from '@/model/ApiToken';
import Tarjeta from '@/model/Tarjeta';
import Pago from '@/model/Pago';
import TarjetaNueva from '@/model/TarjetaNueva';
import RequerimientoCategoria from '@/model/RequerimientoCategoria';
import Usuario from '@/model/Usuario';
import Suministro from '@/model/Suministro';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, 'commit'>

export interface Actions {
  [ActionTypes.INIT](
    { commit }: AugmentedActionContext,
    payload: PersistentState
  ): Promise<void>,

  [ActionTypes.LOGOUT](
    { commit }: AugmentedActionContext
  ): Promise<void>,

  [ActionTypes.DELETE_ACCOUNT](
    { commit }: AugmentedActionContext
  ): Promise<void>,

  [ActionTypes.LOGIN](
    { commit }: AugmentedActionContext,
    payload: { email: string, password: string }
  ): Promise<ApiToken>,

  [ActionTypes.AUTOLOGIN](
    { commit }: AugmentedActionContext,
    payload: { token: string, usuarioId: number }
  ): Promise<ApiToken>,

  [ActionTypes.REFRESH_REQUERIMIENTOS_CATEGORIAS](
    { commit }: AugmentedActionContext
  ): Promise<RequerimientoCategoria[]>,

  [ActionTypes.REFRESH_TARJETAS](
    { commit }: AugmentedActionContext
  ): Promise<Tarjeta[]>,

  [ActionTypes.REFRESH_SUMINISTROS](
    { commit }: AugmentedActionContext
  ): Promise<Suministro[]>,

  [ActionTypes.DEL_USUARIOSUMINISTRO](
    { commit }: AugmentedActionContext,
    payload: number
  ): Promise<void>,

  [ActionTypes.REFRESH_USUARIO](
    { commit }: AugmentedActionContext
  ): Promise<Usuario>,

  [ActionTypes.ADD_TARJETA](
    { commit }: AugmentedActionContext,
    payload: TarjetaNueva
  ): Promise<Tarjeta>,

  [ActionTypes.DEL_TARJETA](
    { commit }: AugmentedActionContext,
    payload: number
  ): Promise<void>,

  [ActionTypes.PAGO_INICIAR](
    { commit }: AugmentedActionContext,
    payload: Pago
  ): Promise<Pago>,

  [ActionTypes.PAGO_GUARDAR](
    { commit }: AugmentedActionContext,
    payload: Pago
  ): Promise<Pago>,
}

export const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.INIT]({ commit }: AugmentedActionContext, payload: PersistentState) {
    return new Promise(() => {
      if (payload.apiToken) {
        commit(MutationTypes.SET_TOKEN, payload.apiToken);
      }
      if (payload.usuario) {
        commit(MutationTypes.SET_USUARIO, payload.usuario);
      }
      commit(MutationTypes.SET_SUMINISTROS, payload.suministros);
      commit(MutationTypes.SET_REQUERIMIENTOS_CATEGORIAS, payload.requerimientosCategorias);
    })
  },

  [ActionTypes.LOGOUT]({ commit }: AugmentedActionContext) {
    return new Promise<void>((resolve) => {
      api.logout();
      commit(MutationTypes.DELETE_USUARIO, undefined);
      commit(MutationTypes.SET_SUMINISTROS, []);
      commit(MutationTypes.SET_REQUERIMIENTOS_CATEGORIAS, []);
      commit(MutationTypes.LOGOUT, undefined);
      resolve();
    })
  },

  [ActionTypes.DELETE_ACCOUNT]({ commit }: AugmentedActionContext) {
    return new Promise<void>((resolve) => {
      api.deleteAccount();
      commit(MutationTypes.DELETE_USUARIO, undefined);
      commit(MutationTypes.SET_SUMINISTROS, []);
      commit(MutationTypes.SET_REQUERIMIENTOS_CATEGORIAS, []);
      commit(MutationTypes.DELETE_ACCOUNT, undefined);
      resolve();
    })
  },

  [ActionTypes.LOGIN]({ commit }: AugmentedActionContext, payload: { email: string, password: string }) {
    return new Promise((resolve, reject) => {
      api.login(payload.email, payload.password).then((token: ApiToken) => {
        commit(MutationTypes.SET_TOKEN, token);

        store.dispatch(ActionTypes.REFRESH_SUMINISTROS, undefined);
        store.dispatch(
          ActionTypes.REFRESH_REQUERIMIENTOS_CATEGORIAS,
          undefined
        );

        resolve(token);
      }).catch((e) => {
        reject(e);
      });
    })
  },

  [ActionTypes.AUTOLOGIN]({ commit }: AugmentedActionContext, payload: { token: string, usuarioId: number }) {
    return new Promise((resolve) => {
      api.autoLogin(payload.token, payload.usuarioId).then((token: ApiToken) => {
        api.setApiToken(token);
        commit(MutationTypes.SET_TOKEN, token);
        resolve(token);
      });
    })
  },

  [ActionTypes.REFRESH_REQUERIMIENTOS_CATEGORIAS]({ commit }: AugmentedActionContext) {
    return new Promise((resolve) => {
      api.getRequerimientosCategorias().then((requerimientosCategorias: RequerimientoCategoria[]) => {
        commit(MutationTypes.SET_REQUERIMIENTOS_CATEGORIAS, requerimientosCategorias);
        resolve(requerimientosCategorias);
      });
    })
  },

  [ActionTypes.REFRESH_TARJETAS]({ commit }: AugmentedActionContext) {
    return new Promise((resolve) => {
      api.getTarjetas().then((tarjetas: Tarjeta[]) => {
        commit(MutationTypes.SET_TARJETAS, tarjetas);
        resolve(tarjetas);
      });
    })
  },

  [ActionTypes.REFRESH_SUMINISTROS]({ commit }: AugmentedActionContext) {
    return new Promise((resolve, reject) => {
      api.getSuministros().then((suministros) => {
        commit(MutationTypes.SET_SUMINISTROS, suministros.rows);
        resolve(suministros.rows);
      }).catch((e) => {
        if (e.response && (e.response.status == 401 || e.response.status == 403)) {
          // Parece que no tenemos un token válido
          store.dispatch(ActionTypes.LOGOUT, undefined);
        }
        reject(e);
      });
    })
  },

  [ActionTypes.DEL_USUARIOSUMINISTRO]({ commit }: AugmentedActionContext, payload: number) {
    return new Promise<void>((resolve, reject) => {
      api.delUsuarioSuministro(payload).then(() => {
        const sums = store.state.suministros.filter((sum: Suministro) => { sum.id != payload });
        commit(MutationTypes.SET_SUMINISTROS, sums);
        resolve();
      }).catch((e) => {
        reject(e);
      });
    })
  },

  [ActionTypes.REFRESH_USUARIO]({ commit }: AugmentedActionContext) {
    return new Promise((resolve, reject) => {
      api.getUsuario(undefined).then((usuario: Usuario) => {
        commit(MutationTypes.SET_USUARIO, usuario);
        resolve(usuario);
      }).catch((e) => {
        if (e.response && (e.response.status == 401 || e.response.status == 403)) {
          // Parece que no tenemos un token válido
          store.dispatch(ActionTypes.LOGOUT, undefined);
        }
        reject(e);
      });
    })
  },

  [ActionTypes.ADD_TARJETA]({ commit }: AugmentedActionContext, payload: TarjetaNueva) {
    return new Promise((resolve) => {
      api.addTarjeta(payload).then((tarjeta: Tarjeta) => {
        commit(MutationTypes.ADD_TARJETA, tarjeta);
        resolve(tarjeta);
      });
    })
  },

  [ActionTypes.DEL_TARJETA]({ commit }: AugmentedActionContext, payload: number) {
    return new Promise<void>((resolve, reject) => {
      api.delTarjeta(payload).then(() => {
        const tarj = store.state.tarjetas.filter((tarjeta: Tarjeta) => { tarjeta.id != payload });
        commit(MutationTypes.SET_TARJETAS, tarj);
        resolve();
      }).catch((e) => {
        reject(e);
      });
    })
  },

  [ActionTypes.PAGO_INICIAR]({ commit }: AugmentedActionContext, payload: Pago) {
    return new Promise((resolve) => {
      store.state.pago = payload;
      resolve(payload);
    })
  },

  [ActionTypes.PAGO_GUARDAR]({ commit }: AugmentedActionContext, payload: Pago) {
    return new Promise((resolve, reject) => {
      api.addPago(payload).then((pago) => {
        resolve(pago);
      }).catch((e) => {
        reject(e);
      });
    })
  },
}
