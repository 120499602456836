import { RequerimientoEstados } from './RequerimientoEstados';

export default class RequerimientoSeguimiento {
  constructor(
    public id: number,
    public descripcion: string,
    public nuevoEstado: RequerimientoEstados | undefined,
    public created: Date,
    public usuario: any,
  ) {
  }

  static fromData(data: any): RequerimientoSeguimiento {
    return new RequerimientoSeguimiento(
      data.id,
      data.descripcion,
      data.nuevoEstado,
      data.created,
      data.usuario,
    );
  }

  static fromDataArray(data: any): RequerimientoSeguimiento[] {
    const res = new Array<RequerimientoSeguimiento>();
    data.forEach(function (el: any) {
      res.push(RequerimientoSeguimiento.fromData(el));
    });
    return res;
  }
}