import Tarjeta from './Tarjeta';
import Pago from './Pago';
import Suministro from './Suministro';

export default class VolatileState {
  tarjetas: Tarjeta[] = [];
  pago: Pago | undefined = undefined;
  suministroSeleccionado: Suministro | undefined = undefined;

  sideNav = {
    disabled: false,
  }
}