import Requerimiento from './Requerimiento';

export default class ArchivoAdjunto {
  public fileName: string | undefined = undefined;
  public usuario: string | undefined = undefined;
  public created: Date | undefined = undefined;
  public updated: Date | undefined = undefined;
  public requerimiento: Requerimiento | undefined = undefined;

  constructor(
    public id: number,
    public contentUrl: string,
  ) {
  }

  static fromData(data: any): ArchivoAdjunto {
    const res = new ArchivoAdjunto(data.numericId, data.contentUrl);

    res.fileName = data.fileName;
    res.created = data.created;
    res.updated = data.updated;
    res.usuario = data.usuario;

    return res;
  }

  static fromDataArray(data: any): ArchivoAdjunto[] {
    const res = new Array<ArchivoAdjunto>();
    data.forEach(function (el: any) {
      res.push(ArchivoAdjunto.fromData(el));
    });
    return res;
  }

  get getFileName(): string {
    return this.contentUrl.split('/').pop() + '';
  }

  get getExtension(): string {
    return this.contentUrl.substr(this.contentUrl.lastIndexOf('.') + 1).toLowerCase();
  }

  get isImage(): boolean {
    switch (this.getExtension) {
      case 'jpg':
      case 'jpeg':
      case 'webp':
      case 'png':
      case 'gif':
      case 'svg':
        return true;
      default:
        return false;
    }
  }

  get isPdf(): boolean {
    switch (this.getExtension) {
      case 'pdf':
        return true;
      default:
        return false;
    }
  }

  get Iri(): string {
    return '/api/archivo_adjuntos/' + this.id;
  }
}