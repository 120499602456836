import { RequerimientoCategoriaAdjuntar } from './RequerimientoCategoriaAdjuntar';

export default class RequerimientoCategoria {
  constructor(
    public id: number,
    public nombre: string,
    public textoAyuda: string,
    public descripcionInicial: string,
    public textoCondiciones: string,
    public sustantivo: string,
    public sustantivoPlural: string,
    public adjuntarFotos: RequerimientoCategoriaAdjuntar,
    public adjuntarFotosObs: string,
    public adjuntarArchivos: RequerimientoCategoriaAdjuntar,
    public adjuntarArchivosObs: string,
    public adjuntarUbicacion: RequerimientoCategoriaAdjuntar,
    public codigo: string,
    public requiereSuministro: boolean,
    public activo: boolean,
    public adjuntosSeguros: boolean,
    public url?: string,
  ) {
  }

  static fromData(data: any): RequerimientoCategoria {
    return new RequerimientoCategoria(
      data.id,
      data.nombre,
      data.textoAyuda,
      data.descripcionInicial,
      data.textoCondiciones,
      data.sustantivo,
      data.sustantivoPlural,
      data.adjuntarFotos,
      data.adjuntarFotosObs,
      data.adjuntarArchivos,
      data.adjuntarArchivosObs,
      data.adjuntarUbicacion,
      data.codigo,
      data.requiereSuministro,
      data.activo,
      data.adjuntosSeguros,
      data.url,
    );
  }

  static fromDataArray(data: any): RequerimientoCategoria[] {
    const res = new Array<RequerimientoCategoria>();
    data.forEach(function (el: any) {
      res.push(RequerimientoCategoria.fromData(el));
    });
    return res;
  }

  get Iri(): string {
    return '/api/requerimiento_categorias/' + this.id;
  }
}
