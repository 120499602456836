import Suministro from './Suministro';

export enum FacturaPagadaEstado {
  Impaga = 0,
  Pagada = 1,
  Pagando = 9,
}

export default class Factura {
  constructor(
    public id: number,
    public numero: string,
    public importe: number,
    public suministro: Suministro | string,
    public periodo: string,
    public fecha: Date | undefined,
    public vencimiento: Date,
    public consumo: number,
    public pagada: boolean,
    public estadoPago: FacturaPagadaEstado,
    public descargable: boolean,
    public vencida: boolean,
    public token: string,
  ) {
  }

  isPagada(): boolean {
    return this.pagada || this.estadoPago == FacturaPagadaEstado.Pagada;
  }

  isPagable(): boolean {
    if (this.suministro != undefined &&
      this.suministro instanceof Suministro &&
      this.suministro.pagoInhabilitado) {
      return false;
    }
    return this.pagada == false && this.estadoPago == FacturaPagadaEstado.Impaga;
  }

  static fromData(data: any): Factura {
    return new Factura(
      data.id,
      data.numero,
      data.importe,
      data.suministro,
      data.periodo,
      data.fecha,
      data.vencimiento,
      data.consumo,
      data.pagada,
      data.estadoPago,
      data.descargable,
      data.vencida,
      data.token,
    );
  }

  static fromDataArray(data: any): Factura[] {
    const res = new Array<Factura>();
    data.forEach(function (el: any) {
      res.push(Factura.fromData(el));
    });
    return res;
  }

  get Iri(): string {
    return '/api/facturas/' + this.id;
  }
}