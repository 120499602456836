export default class Corte {
  constructor(
    public id: number,
    public tipo: string,
    public inicio: Date,
    public fin: Date,
    public zona: string,
    public motivo: string,
    public obs: string,
    public created: Date,
    public updated: Date,
    public suministros: number[] | undefined = undefined,
  ) {
  }

  static fromData(data: any): Corte {
    return new Corte(
      data.id,
      data.tipo,
      data.inicio,
      data.fin,
      data.zona,
      data.motivo,
      data.obs,
      data.created,
      data.updated,
      Corte.fromIriList(data.suministros),
    );
  }

  static fromIriList(data: string[]): number[] {
    const res = new Array<number>();
    data.forEach(function (el: string) {
      res.push(parseInt(el.replace(/\/api\/([A-Za-z_])+\//, '')));
    });
    return res;
  }

  static fromDataArray(data: any): Corte[] {
    const res = new Array<Corte>();
    data.forEach(function (el: any) {
      res.push(Corte.fromData(el));
    });
    return res;
  }

  get Iri(): string {
    return '/api/cortes/' + this.id;
  }
}