import axios from 'axios';
//import { cacheAdapterEnhancer, throttleAdapterEnhancer, retryAdapterEnhancer } from 'axios-extensions';
import ApiToken from '@/model/ApiToken';
import RowCollection from '@/model/RowCollection';
import Usuario from '@/model/Usuario';
import UsuarioPreferencias from '@/model/UsuarioPreferencias';
import UsuarioNuevo from '@/model/UsuarioNuevo';
import Factura from '@/model/Factura';
import ArchivoFactura from '@/model/ArchivoFactura';
import ArchivoSubida from '@/model/ArchivoSubida';
import Corte from '@/model/Corte';
import Requerimiento from '@/model/Requerimiento';
import RequerimientoNuevo from '@/model/RequerimientoNuevo';
import Suministro from '@/model/Suministro';
import SuministroMail from '@/model/SuministroMail';
import UsuarioSuministro from '@/model/UsuarioSuministro';
import Tarjeta from '@/model/Tarjeta';
import TarjetaNueva from '@/model/TarjetaNueva';
import { PagoMetodos } from '@/model/PagoMetodos';
import Pago from '@/model/Pago';
import Mensaje from '@/model/Mensaje';
import RequerimientoCategoria from '@/model/RequerimientoCategoria';
import RequerimientoSeguimiento from '@/model/RequerimientoSeguimiento';
import RequerimientoSeguimientoNuevo from '@/model/RequerimientoSeguimientoNuevo';
import Consumo from '@/model/Consumo';
import Indicador from '@/model/Indicador';
import ArchivoAdjunto from '@/model/ArchivoAdjunto';

const EP_GET_TOKEN = '/authentication_token';
const EP_AUTH_STATUS = '/api/auth/status';
const EP_RESET_PASSWORD = '/reset_password';
const EP_LOGOUT = '/api/invalidate_token';
const EP_ELIMINAR_CUENTA = '/api/delete_account';
const EP_RESEND_CONFIRMATION_MAIL = '/resend_mail';
const EP_MOTD = '/api/oob/usuario/motd';

const EP_USUARIOS = '/api/usuarios';
//const EP_USUARIO_PREFERENCIAS = '/api/usuario_preferencias';
const EP_FACTURAS = '/api/facturas';
const EP_CONSUMOS = '/api/consumos';
const EP_INDICADORES = '/api/indicadors';
const EP_SUMINISTROS = '/api/suministros';
const EP_SUMINISTRO_MAILS = '/api/suministro_mails';
const EP_SUMINISTRO_MAILS_ENVIAR = '/api/oob/suministro_mails/enviar_email';
const EP_USUARIO_SUMINISTROS = '/api/usuario_suministros';
const EP_TARJETAS = '/api/tarjetas';
const EP_PAGOS = '/api/pagos';
const EP_MENSAJES = '/api/mensajes';
const EP_REQUERIMIENTOS = '/api/requerimientos';
const EP_REQUERIMIENTOS_CATEGORIAS = '/api/requerimiento_categorias';
const EP_REQUERIMIENTOS_SEGUIMIENTOS = '/api/requerimiento_seguimientos';
//const EP_ARCHVIOS_ADJUNTOS = '/api/archivo_adjuntos';
const EP_ARCHIVOS_FACTURAS = '/api/archivo_facturas';
const EP_ARCHIVOS_SUBIDAS = '/api/archivo_subidas';
const EP_CORTES = '/api/cortes';
const EP_PERSONA_INQUIRIES = '/api/persona_inquiries';
const EP_PERSONA_INQUIRIES_ASOCIAR = '/api/persona_inquiries/asociar';
const EP_EXPORTAR = '/api/oob/exportar';
const EP_OTP_GENERAR = '/api/oob/otp/generar';
const EP_OTP_HABILITAR = '/api/oob/otp/habilitar';
const EP_OTP_INHABILITAR = '/api/oob/otp/inhabilitar';
const EP_VERIFICAR_TELEFONO_INICIAR = '/api/oob/usuario/verificar/telefono/iniciar';
const EP_VERIFICAR_TELEFONO_TERMINAR = '/api/oob/usuario/verificar/telefono/terminar';
const EP_VERIFICAR_TELEFONO_NOGRACIAS = '/api/oob/usuario/verificar/telefono/no_gracias';

const EP_ADJUNTO = '/api/oob/adjunto/ver';

export class Api {
  private apiToken: ApiToken | undefined = undefined;

  headers = {
    'Content-Type': 'application/json',
    // Timeout de 3s en desarrollo, 20s en producción
    timeout: import.meta.env.MODE == 'production' ? 20000 : 3000,
    'Authorization': '',
    'Cache-Control': 'no-cache',
  };

  apiClient = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
    //headers: this.headers,
    //adapter: cacheAdapterEnhancer(throttleAdapterEnhancer(retryAdapterEnhancer(axios.defaults.adapter!), { threshold: 3 * 1000 })),
  });

  setApiToken(apiToken: ApiToken | undefined) {
    this.apiToken = apiToken;
    if (this.apiToken !== undefined) {
      this.headers.Authorization = 'Bearer ' + this.apiToken.token;
      this.apiClient.defaults.headers.common = this.headers;
    } else {
      this.headers.Authorization = '';
    }
  }

  public async login(email: string, password: string): Promise<ApiToken> {
    return new Promise<ApiToken>((resolve, reject) => {
      const payload = {
        email: email,
        password: password,
      };
      this.apiClient.post(EP_GET_TOKEN, payload).then((res) => {
        const tkn = ApiToken.fromData(res.data);
        this.headers.Authorization = 'Bearer ' + tkn.token;
        this.apiClient.defaults.headers.common = this.headers;
        this.setApiToken(tkn)
        resolve(tkn);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  public async autoLogin(token: string, usuarioId: number): Promise<ApiToken> {
    return new Promise<ApiToken>((resolve) => {
      console.log('api.autoLogin ok');
      this.headers.Authorization = 'Bearer ' + token;
      this.apiClient.defaults.headers.common = this.headers;
      resolve(new ApiToken(token, usuarioId));
    });
  }

  public async logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiClient.post(EP_LOGOUT).then(() => {
        console.log('api.logout ok');
        this.headers.Authorization = '';
        resolve();
      }).catch((e) => {
        console.log('api.logout error ' + e);
        reject(e);
      });
    });
  }

  public async deleteAccount(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiClient.post(EP_ELIMINAR_CUENTA).then(() => {
        console.log('api.deleteAccount ok');
        this.headers.Authorization = '';
        resolve();
      }).catch((e) => {
        console.log('api.deleteAccount error ' + e);
        reject(e);
      });
    });
  }

  public async getStatus(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiClient.get(EP_AUTH_STATUS).then((res) => {
        resolve(res.data);
      }).catch((e) => {
        console.log('api.getStatus error ' + e);
        reject(e);
      });
    });
  }

  public async getUsuario(id: number | undefined): Promise<Usuario> {
    return new Promise<Usuario>((resolve, reject) => {
      if (id == undefined) {
        id = this.apiToken?.usuarioId!;
      }
      this.apiClient.get(EP_USUARIOS + '/' + id.toString()).then((res) => {
        resolve(Usuario.fromData(res.data));
      }).catch((e) => {
        console.log('api.getUsuario error ' + e);
        reject(e);
      });
    });
  }

  public async getUsuarios(query: any = undefined): Promise<RowCollection<Usuario>> {
    return new Promise<RowCollection<Usuario>>((resolve, reject) => {
      this.apiClient.get(EP_USUARIOS, { params: query }).then((res) => {
        resolve(new RowCollection<Usuario>(
          Usuario.fromDataArray(res.data['hydra:member']),
          res.data['hydra:totalItems'],
        ));
      }).catch((e) => {
        console.log('api.getUsuarios error ' + e);
        reject(e);
      });
    });
  }

  public async addUsuario(usuarioNuevo: UsuarioNuevo): Promise<Usuario> {
    return new Promise<Usuario>((resolve, reject) => {
      const payload = {
        email: usuarioNuevo.email,
        plainPassword: usuarioNuevo.password,
        telefono: usuarioNuevo.telefono,
        documento: usuarioNuevo.documento,
        cuit: usuarioNuevo.cuit,
        nombre: usuarioNuevo.nombre,
        apellido: usuarioNuevo.apellido,
        razonSocial: usuarioNuevo.razonSocial,
        aceptarLegal: usuarioNuevo.aceptarLegal,
        recibirPapel: usuarioNuevo.recibirPapel,
      };

      this.apiClient.post(EP_USUARIOS, payload).then((res) => {
        resolve(Usuario.fromData(res.data));
      }).catch((e) => {
        console.log('api.addUsuario error ' + e);
        reject(e);
      });
    });
  }

  public async updateUsuario(usuario: Usuario, password: string | undefined = undefined): Promise<Usuario> {
    return new Promise<Usuario>((resolve, reject) => {
      const payload = {
        email: usuario.email,
        plainPassword: password,
        telefono: usuario.telefono,
        documento: usuario.documento,
        cuit: usuario.cuit,
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        razonSocial: usuario.razonSocial,
      };

      this.apiClient.patch(usuario.Iri, payload, { headers: { 'Content-Type': 'application/merge-patch+json' } }).then((res) => {
        resolve(Usuario.fromData(res.data));
      }).catch((e) => {
        console.log('api.updateUsuario error ' + e);
        reject(e);
      });
    });
  }

  public async updateUsuarioPreferencias(preferencias: UsuarioPreferencias): Promise<UsuarioPreferencias> {
    return new Promise<UsuarioPreferencias>((resolve, reject) => {
      const payload = {
        ...preferencias.mailFactura !== undefined && { mailFactura: preferencias.mailFactura },
        ...preferencias.mailVencimiento !== undefined && { mailVencimiento: preferencias.mailVencimiento },
        ...preferencias.mailRequerimiento !== undefined && { mailRequerimiento: preferencias.mailRequerimiento },
        ...preferencias.smsVencimiento !== undefined && { smsVencimiento: preferencias.smsVencimiento },
        ...preferencias.smsCorte !== undefined && { smsCorte: preferencias.smsCorte },
        ...preferencias.smsOtros !== undefined && { smsOtros: preferencias.smsOtros },
        ...preferencias.smsVia !== undefined && { smsVia: preferencias.smsVia },
      };

      this.apiClient.patch(preferencias.Iri, payload, { headers: { 'Content-Type': 'application/merge-patch+json' } }).then((res) => {
        resolve(UsuarioPreferencias.fromData(res.data));
      }).catch((e) => {
        console.log('api.updateUsuarioPreferencias error ' + e);
        reject(e);
      });
    });
  }

  public async verificarTelefonoIniciar(telefono: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const payload = {
        telefono: telefono,
      };

      this.apiClient.post(EP_VERIFICAR_TELEFONO_INICIAR, payload).then((res) => {
        resolve(res.data['mensaje']['id']);
      }).catch((e) => {
        console.log('api.verificarTelefonoIniciar error ' + e);
        reject(e);
      });
    });
  }

  public async verificarTelefonoTerminar(mensajeId: string, codigo: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const payload = {
        mensajeId: mensajeId,
        codigo: codigo,
      };

      this.apiClient.post(EP_VERIFICAR_TELEFONO_TERMINAR, payload).then(() => {
        resolve(true);
      }).catch((e) => {
        console.log('api.verificarTelefonoTerminar error ' + e);
        reject(e);
      });
    });
  }

  public async verificarTelefonoNoGracias(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const payload = {};

      this.apiClient.post(EP_VERIFICAR_TELEFONO_NOGRACIAS, payload).then((res) => {
        resolve(true);
      }).catch((e) => {
        console.log('api.verificarTelefonoNoGracias error ' + e);
        reject(e);
      });
    });
  }

  public async adminUpdateUsuario(usuario: Usuario, password: string | undefined = undefined): Promise<Usuario> {
    return new Promise<Usuario>((resolve, reject) => {
      const payload = {
        email: usuario.email,
        plainPassword: password,
        telefono: usuario.telefono,
        documento: usuario.documento,
        cuit: usuario.cuit,
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        razonSocial: usuario.razonSocial,
        identidadValidada: usuario.identidadValidada,
        emailValidado: usuario.emailValidado,
        telefonoValidado: usuario.telefonoValidado,
      };

      this.apiClient.patch(usuario.Iri + '/admin', payload, { headers: { 'Content-Type': 'application/merge-patch+json' } }).then((res) => {
        resolve(Usuario.fromData(res.data));
      }).catch((e) => {
        console.log('api.adminUpdateUsuario error ' + e);
        reject(e);
      });
    });
  }

  public async restablecerContrasena(email: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const payload = {
        email: email,
      };

      this.apiClient.post(EP_RESET_PASSWORD, payload).then(() => {
        resolve();
      }).catch((e) => {
        console.log('api.restablecerContrasena error ' + e);
        reject(e);
      });
    });
  }

  public async reenviarCorreoConfirmacion(email: string | undefined = undefined): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const payload = email ? {
        email: email,
      } : undefined;

      this.apiClient.post(EP_RESEND_CONFIRMATION_MAIL, payload).then(() => {
        resolve();
      }).catch((e) => {
        console.log('api.reenviarCorreoConfirmacion error ' + e);
        reject(e);
      });
    });
  }

  public async getMotd(): Promise<String | undefined> {
    return new Promise<String | undefined>((resolve, reject) => {
      this.apiClient.get(EP_MOTD).then((res) => {
        resolve(res.data['motd']);
      }).catch((e) => {
        console.log('api.getMotd error ' + e);
        reject(e);
      });
    });
  }

  public async getFacturas(query: any = undefined): Promise<RowCollection<Factura>> {
    return new Promise<RowCollection<Factura>>((resolve, reject) => {
      this.apiClient.get(EP_FACTURAS, { params: query ?? { usuario: this.apiToken?.usuarioId! } }).then((res) => {
        resolve(new RowCollection<Factura>(
          Factura.fromDataArray(res.data['hydra:member']),
          res.data['hydra:totalItems'],
        ));
      }).catch((e) => {
        console.log('api.getFacturas error ' + e);
        reject(e);
      });
    });
  }

  public async getConsumos(query: any = undefined): Promise<Array<Consumo>> {
    return new Promise<Array<Consumo>>((resolve, reject) => {
      this.apiClient.get(EP_CONSUMOS, { params: query }).then((res) => {
        resolve(Consumo.fromDataArray(res.data['hydra:member']));
      }).catch((e) => {
        console.log('api.getConsumos error ' + e);
        reject(e);
      });
    });
  }

  public async getIndicadores(query: any = undefined): Promise<Array<Indicador>> {
    return new Promise<Array<Indicador>>((resolve, reject) => {
      this.apiClient.get(EP_INDICADORES, { params: query }).then((res) => {
        resolve(Indicador.fromDataArray(res.data['hydra:member']));
      }).catch((e) => {
        console.log('api.getIndicadores error ' + e);
        reject(e);
      });
    });
  }

  public async getRequerimientos(query: any = undefined): Promise<RowCollection<Requerimiento>> {
    return new Promise<RowCollection<Requerimiento>>((resolve, reject) => {
      this.apiClient.get(EP_REQUERIMIENTOS, { params: query }).then((res) => {
        resolve(
          new RowCollection<Requerimiento>(
            Requerimiento.fromDataArray(res.data['hydra:member']),
            res.data['hydra:totalItems'],
          )
        );
      }).catch((e) => {
        console.log('api.getRequerimientos error ' + e);
        reject(e);
      });
    });
  }

  public async getRequerimiento(id: number): Promise<Requerimiento> {
    return new Promise<Requerimiento>((resolve, reject) => {
      this.apiClient.get(EP_REQUERIMIENTOS + '/' + id).then((res) => {
        resolve(Requerimiento.fromData(res.data));
      }).catch((e) => {
        console.log('api.getRequerimiento error ' + e);
        reject(e);
      });
    });
  }

  public async setRequerimientoEstado(requerimiento: Requerimiento, nuevoEstado: any): Promise<Requerimiento> {
    return new Promise<Requerimiento>((resolve, reject) => {
      const payload = {
        estado: nuevoEstado,
      };
      this.apiClient.patch(EP_REQUERIMIENTOS + '/' + requerimiento.id + '/estado', payload, { headers: { 'Content-Type': 'application/merge-patch+json' } }).then((res) => {
        resolve(Requerimiento.fromData(res.data));
      }).catch((e) => {
        console.log('api.setRequerimientoEstado error ' + e);
        reject(e);
      });
    });
  }

  public async setRequerimientoMarcaNovedadUsuario(requerimiento: Requerimiento, nuevoEstado: boolean): Promise<Requerimiento> {
    return new Promise<Requerimiento>((resolve, reject) => {
      const payload = {
        novedadesUsuario: nuevoEstado,
      };
      this.apiClient.patch(EP_REQUERIMIENTOS + '/' + requerimiento.id + '/marca_novedad_usuario', payload, { headers: { 'Content-Type': 'application/merge-patch+json' } }).then((res) => {
        resolve(Requerimiento.fromData(res.data));
      }).catch((e) => {
        console.log('api.setRequerimientoMarcaNovedadUsuario error ' + e);
        reject(e);
      });
    });
  }

  public async setRequerimientoMarcaNovedadEncargado(requerimiento: Requerimiento, nuevoEstado: boolean): Promise<Requerimiento> {
    return new Promise<Requerimiento>((resolve, reject) => {
      const payload = {
        novedadesEncargado: nuevoEstado,
      };
      this.apiClient.patch(EP_REQUERIMIENTOS + '/' + requerimiento.id + '/marca_novedad_encargado', payload, { headers: { 'Content-Type': 'application/merge-patch+json' } }).then((res) => {
        resolve(Requerimiento.fromData(res.data));
      }).catch((e) => {
        console.log('api.setRequerimientoMarcaNovedadEncargado error ' + e);
        reject(e);
      });
    });
  }

  public async setRequerimientoEncargado(requerimiento: Requerimiento): Promise<Requerimiento> {
    return new Promise<Requerimiento>((resolve, reject) => {
      const payload = {
        encargado: requerimiento.encargado ? requerimiento.encargado.Iri : undefined,
      };
      this.apiClient.patch(EP_REQUERIMIENTOS + '/' + requerimiento.id + '/encargado', payload, { headers: { 'Content-Type': 'application/merge-patch+json' } }).then((res) => {
        resolve(Requerimiento.fromData(res.data));
      }).catch((e) => {
        console.log('api.setRequerimientoEncargado error ' + e);
        reject(e);
      });
    });
  }

  public async getArchivosFacturas(query: any = undefined): Promise<RowCollection<ArchivoFactura>> {
    return new Promise<RowCollection<ArchivoFactura>>((resolve, reject) => {
      this.apiClient.get(EP_ARCHIVOS_FACTURAS, { params: query }).then((res) => {
        resolve(
          new RowCollection<ArchivoFactura>(
            ArchivoFactura.fromDataArray(res.data['hydra:member']),
            res.data['hydra:totalItems'],
          )
        );
      }).catch((e) => {
        console.log('api.getArchivosFacturas error ' + e);
        reject(e);
      });
    });
  }

  public async getArchivosSubidas(query: any = undefined): Promise<RowCollection<ArchivoSubida>> {
    return new Promise<RowCollection<ArchivoSubida>>((resolve, reject) => {
      this.apiClient.get(EP_ARCHIVOS_SUBIDAS, { params: query }).then((res) => {
        resolve(
          new RowCollection<ArchivoSubida>(
            ArchivoSubida.fromDataArray(res.data['hydra:member']),
            res.data['hydra:totalItems'],
          )
        );
      }).catch((e) => {
        console.log('api.getArchivosSubidas error ' + e);
        reject(e);
      });
    });
  }

  public async getCortes(query: any = undefined): Promise<RowCollection<Corte>> {
    return new Promise<RowCollection<Corte>>((resolve, reject) => {
      this.apiClient.get(EP_CORTES, { params: query }).then((res) => {
        resolve(
          new RowCollection<Corte>(
            Corte.fromDataArray(res.data['hydra:member']),
            res.data['hydra:totalItems'],
          )
        );
      }).catch((e) => {
        console.log('api.getCortes error ' + e);
        reject(e);
      });
    });
  }

  public async getSuministros(query: any = undefined): Promise<RowCollection<Suministro>> {
    return new Promise<RowCollection<Suministro>>((resolve, reject) => {
      this.apiClient.get(EP_SUMINISTROS, { params: query ?? { usuario: this.apiToken?.usuarioId! } }).then((res) => {
        resolve(
          new RowCollection<Suministro>(
            Suministro.fromDataArray(res.data['hydra:member']),
            res.data['hydra:totalItems'],
          )
        );
      }).catch((e) => {
        console.log('api.getSuministros error ' + e);
        reject(e);
      });
    });
  }

  public async addUsuarioSuministro(usuarioSuministro: UsuarioSuministro): Promise<UsuarioSuministro> {
    return new Promise<UsuarioSuministro>((resolve, reject) => {
      const payload: { suministro: string, documento: string } = {
        suministro: usuarioSuministro.suministro,
        documento: usuarioSuministro.documento,
      };
      this.apiClient.post(EP_USUARIO_SUMINISTROS, payload).then((res) => {
        resolve(UsuarioSuministro.fromData(res.data));
      }).catch(({ response }) => {
        reject(response.data['hydra:description']);
      });
    });
  }

  public async delUsuarioSuministro(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiClient.delete(EP_USUARIO_SUMINISTROS + '/' + id).then(() => {
        resolve();
      }).catch((e) => {
        console.log('api.delUsuarioSuministro error ' + e);
        reject(e);
      });
    });
  }

  public async getSuministroMails(query: any = undefined): Promise<RowCollection<SuministroMail>> {
    return new Promise<RowCollection<SuministroMail>>((resolve, reject) => {
      this.apiClient.get(EP_SUMINISTRO_MAILS, { params: query }).then((res) => {
        resolve(new RowCollection<SuministroMail>(
          SuministroMail.fromDataArray(res.data['hydra:member']),
          res.data['hydra:totalItems'],
        ));

      }).catch((e) => {
        console.log('api.getSuministroMails error ' + e);
        reject(e);
      });
    });
  }

  public async updateSuministroMail(suministroMail: SuministroMail): Promise<SuministroMail> {
    return new Promise<SuministroMail>((resolve, reject) => {
      const payload = {
        validado: suministroMail.validado,
        desuscribir: suministroMail.desuscribir,
        email: suministroMail.email,
      };

      this.apiClient.patch(suministroMail.Iri, payload, { headers: { 'Content-Type': 'application/merge-patch+json' } }).then((res) => {
        resolve(SuministroMail.fromData(res.data));
      }).catch((e) => {
        console.log('api.updateSuministroMail error ' + e);
        reject(e);
      });
    });
  }

  public async delSuministroMail(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiClient.delete(EP_SUMINISTRO_MAILS + '/' + id).then(() => {
        resolve();
      }).catch((e) => {
        console.log('api.delSuministroMails error ' + e);
        reject(e);
      });
    });
  }

  public async addSuministroMail(suministroMail: SuministroMail): Promise<SuministroMail> {
    return new Promise<SuministroMail>((resolve, reject) => {
      const payload = {
        suministro:
          (suministroMail.suministro instanceof Suministro)
            ? suministroMail.suministro.Iri
            : (typeof suministroMail.suministro == 'number' ? '/api/suministros/' + suministroMail.suministro : suministroMail.suministro),
        email: suministroMail.email,
      };
      this.apiClient.post(EP_SUMINISTRO_MAILS, payload).then((res) => {
        resolve(SuministroMail.fromData(res.data));
      }).catch(({ response }) => {
        reject(response.data['hydra:description']);
      });
    });
  }

  public async suministroMailEnviarPorMail(email: string) {
    return new Promise<void>((resolve, reject) => {
      const payload: { email: string } = {
        email: email,
      };
      this.apiClient.post(EP_SUMINISTRO_MAILS_ENVIAR, payload).then(() => {
        resolve();
      }).catch(({ response }) => {
        reject(response.data['hydra:description']);
      });
    });
  }

  public async getRequerimientosCategorias(): Promise<Array<RequerimientoCategoria>> {
    return new Promise<Array<RequerimientoCategoria>>((resolve, reject) => {
      this.apiClient.get(EP_REQUERIMIENTOS_CATEGORIAS).then((res) => {
        resolve(RequerimientoCategoria.fromDataArray(res.data['hydra:member']))
      }).catch((e) => {
        console.log('api.getRequerimientosCategorias error ' + e);
        reject(e);
      });
    });
  }

  public async getTarjetas(): Promise<Array<Tarjeta>> {
    return new Promise<Array<Tarjeta>>((resolve, reject) => {
      this.apiClient.get(EP_TARJETAS).then((res) => {
        resolve(Tarjeta.fromDataArray(res.data['hydra:member']));
      }).catch((e) => {
        console.log('api.getTarjetas error ' + e);
        reject(e);
      });
    });
  }

  public async addTarjeta(tarjetaNueva: TarjetaNueva): Promise<Tarjeta> {
    return new Promise<Tarjeta>((resolve, reject) => {
      //console.log('addTarjeta ' + JSON.stringify(tarjetaNueva));
      const payload = {
        numero: tarjetaNueva.numero,
        vencimiento: tarjetaNueva.vencimiento,
        titularNombre: tarjetaNueva.titularNombre,
        titularFechaNac: tarjetaNueva.titularFechaNac,
        titularDocumento: tarjetaNueva.titularDocumento,
        cvc: tarjetaNueva.cvc,
        obs: tarjetaNueva.obs,
      };
      this.apiClient.post(EP_TARJETAS, payload).then((res) => {
        resolve(Tarjeta.fromData(res.data));
      }).catch((e) => {
        console.log('api.addTarjeta error ' + e);
        reject(e);
      });
    });
  }

  public async addPago(pagoNuevo: Pago): Promise<Pago> {
    return new Promise<Pago>((resolve, reject) => {
      const payload = {
        metodo: pagoNuevo.metodo,
        tarjeta: pagoNuevo.tarjeta === undefined ? undefined : pagoNuevo.tarjeta.Iri,
        facturas: pagoNuevo.facturas.map(item => item.Iri),
        tarjetaCvc: pagoNuevo.tarjetaCvc,
        origen: 'web',
      };
      this.apiClient.post(EP_PAGOS, payload).then((res) => {
        resolve(Pago.fromData(res.data));
      }).catch((e) => {
        console.log('api.addPago error ' + e);
        reject(e);
      });
    });
  }

  public async getPago(id: number): Promise<Pago> {
    return new Promise<Pago>((resolve, reject) => {
      this.apiClient.get(EP_PAGOS + '/' + id.toString()).then((res) => {
        resolve(Pago.fromData(res.data));
      }).catch((e) => {
        console.log('api.getPago error ' + e);
        reject(e);
      });
    });
  }

  public async getPagos(query: any = undefined): Promise<RowCollection<Pago>> {
    return new Promise<RowCollection<Pago>>((resolve, reject) => {
      this.apiClient.get(EP_PAGOS, { params: query ?? { usuario: this.apiToken?.usuarioId! } }).then((res) => {
        resolve(new RowCollection<Pago>(
          Pago.fromDataArray(res.data['hydra:member']),
          res.data['hydra:totalItems'],
        ));
      }).catch((e) => {
        console.log('api.getPagos error ' + e);
        reject(e);
      });
    });
  }

  public async getMensajes(query: any = undefined): Promise<RowCollection<Mensaje>> {
    return new Promise<RowCollection<Mensaje>>((resolve, reject) => {
      this.apiClient.get(EP_MENSAJES, { params: query ?? { usuario: this.apiToken?.usuarioId! } }).then((res) => {
        resolve(new RowCollection<Mensaje>(
          Mensaje.fromDataArray(res.data['hydra:member']),
          res.data['hydra:totalItems'],
        ));
      }).catch((e) => {
        console.log('api.getMensajes error ' + e);
        reject(e);
      });
    });
  }

  public async addRequerimiento(requerimientoNuevo: RequerimientoNuevo): Promise<Requerimiento> {
    return new Promise<Requerimiento>((resolve, reject) => {
      const payload = {
        descripcion: requerimientoNuevo.descripcion,
        categoria: requerimientoNuevo.categoria.Iri,
        adjuntos: requerimientoNuevo.adjuntos.map(item => item.Iri),
        ubicacion: requerimientoNuevo.ubicacion,
        suministro: requerimientoNuevo.suministro == undefined ? undefined : requerimientoNuevo.suministro.Iri,
      };

      this.apiClient.post(EP_REQUERIMIENTOS, payload).then((res) => {
        resolve(Requerimiento.fromData(res.data));
      }).catch((e) => {
        console.log('api.addRequerimiento error ' + e);
        reject(e);
      });
    });
  }

  public async addRequerimientoSeguimiento(seguimientoNuevo: RequerimientoSeguimientoNuevo): Promise<RequerimientoSeguimiento> {
    return new Promise<RequerimientoSeguimiento>((resolve, reject) => {
      const payload = {
        requerimiento: seguimientoNuevo.requerimiento.Iri,
        descripcion: seguimientoNuevo.descripcion,
      };

      this.apiClient.post(EP_REQUERIMIENTOS_SEGUIMIENTOS, payload).then((res) => {
        resolve(RequerimientoSeguimiento.fromData(res.data));
      }).catch((e) => {
        console.log('api.addRequerimientoSeguimiento error ' + e);
        reject(e);
      });
    });
  }

  public async getAdjuntoBase64(contentUrl: string, otp: string): Promise<any> {
    return this.apiClient.get(EP_ADJUNTO, {
      params: {
        contentUrl: contentUrl,
        otp: otp,
      }, responseType: 'blob',
    }).then((res) => { console.log(typeof (res.data)); return res.data; });
  }

  /**
   * Asociar un archivo adjunto a un requerimiento.
   */
  public async setArchivoAdjuntoRequerimieto(adjunto: ArchivoAdjunto, requerimiento: Requerimiento): Promise<ArchivoAdjunto> {
    return new Promise<ArchivoAdjunto>((resolve, reject) => {
      const payload = {
        requerimiento: requerimiento.Iri,
      };

      this.apiClient.patch(adjunto.Iri, payload, { headers: { 'Content-Type': 'application/merge-patch+json' } }).then((res) => {
        resolve(ArchivoAdjunto.fromData(res.data));
      }).catch((e) => {
        console.log('api.setArchivoAdjuntoRequerimieto error ' + e);
        reject(e);
      });
    });
  }

  public async delTarjeta(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiClient.delete(EP_TARJETAS + '/' + id).then(() => {
        resolve();
      }).catch((e) => {
        console.log('api.delTarjeta error ' + e);
        reject(e);
      });
    });
  }

  public async addPersonaInquiry(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const payload = {
        id: id,
        usuario: EP_USUARIOS + '/' + this.apiToken?.usuarioId!,
      };
      this.apiClient.post(EP_PERSONA_INQUIRIES, payload).then(() => {
        console.log('api.addPersonaInquiry ok');
        resolve();
      }).catch((e) => {
        console.log('api.addPersonaInquiry error ' + e);
        reject();
      });
    });
  }

  public async personaAsociarInquiry(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const payload = {
        id: id,
        usuario: EP_USUARIOS + '/' + this.apiToken?.usuarioId!,
      };
      this.apiClient.post(EP_PERSONA_INQUIRIES_ASOCIAR, payload).then(() => {
        console.log('api.personaAsociarInquiry ok');
        resolve();
      }).catch((e) => {
        console.log('api.personaAsociarInquiry error ' + e);
        reject();
      });
    });
  }

  public async exportar(archivo: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiClient.get(EP_EXPORTAR + '/' + archivo).then((response) => {
        console.log('api.exportar ok');
        const blob = new Blob([response.data], { type: 'text/csv' }),
          url = window.URL.createObjectURL(blob)
        window.open(url)
        resolve();
      }).catch((e) => {
        console.log('api.exportar error ' + e);
        reject();
      });
    });
  }

  public async otpGenerar(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.apiClient.post(EP_OTP_GENERAR).then((response) => {
        console.log('api.otpGenerar ok');
        console.log(response.data);
        resolve(response.data.secret);
      }).catch((e) => {
        console.log('api.otpGenerar error ' + e);
        reject();
      });
    });
  }

  public async otpHabilitar(otp: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const payload = {
        otp: otp,
      };
      this.apiClient.post(EP_OTP_HABILITAR, payload).then(() => {
        console.log('api.otpHabilitar ok');
        resolve();
      }).catch((e) => {
        console.log('api.otpHabilitar error ' + e);
        reject();
      });
    });
  }

  public async otpInhabilitar(otp: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const payload = {
        otp: otp,
      };
      this.apiClient.post(EP_OTP_INHABILITAR, payload).then(() => {
        console.log('api.otpInhabilitar ok');
        resolve();
      }).catch((e) => {
        console.log('api.otpInhabilitar error ' + e);
        reject();
      });
    });
  }

}

export const api = new Api();

export function useApi(): Api {
  return api;
}