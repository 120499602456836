import type { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { State } from './state';
import ApiToken from '@/model/ApiToken';
import { api } from '@/api';
import Usuario from '@/model/Usuario';
import Suministro from '@/model/Suministro';
import Tarjeta from '@/model/Tarjeta';
import RequerimientoCategoria from '@/model/RequerimientoCategoria';
import PersistentState from '@/model/PersistentState';

export type Mutations<S = State> = {
  [MutationTypes.SET_SIDENAV_DISABLED](state: S, payload: boolean | undefined): void
  [MutationTypes.SET_TOKEN](state: S, payload: ApiToken): void
  [MutationTypes.LOGOUT](state: S): void
  [MutationTypes.DELETE_ACCOUNT](state: S): void
  [MutationTypes.SET_USUARIO](state: S, payload: Usuario): void
  [MutationTypes.DELETE_USUARIO](state: S): void
  [MutationTypes.SET_SUMINISTROS](state: S, payload: Suministro[]): void
  [MutationTypes.SET_REQUERIMIENTOS_CATEGORIAS](state: S, payload: RequerimientoCategoria[]): void
  [MutationTypes.SET_TARJETAS](state: S, payload: Tarjeta[]): void
  [MutationTypes.ADD_TARJETA](state: S, payload: Tarjeta): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_SIDENAV_DISABLED](state: State, payload: boolean | undefined) {
    state.sideNav.disabled = payload;
  },
  [MutationTypes.SET_TOKEN](state: State, payload: ApiToken) {
    state.apiToken = payload;
    api.setApiToken(payload);
  },
  [MutationTypes.LOGOUT](state: State) {
    state.apiToken = undefined;
    state.persistentState = new PersistentState();
    localStorage.removeItem('inicio_facturas');
    localStorage.removeItem('persistentState');
    api.setApiToken(undefined);
  },
  [MutationTypes.DELETE_ACCOUNT](state: State) {
    state.apiToken = undefined;
    state.persistentState = new PersistentState();
    localStorage.removeItem('inicio_facturas');
    localStorage.removeItem('persistentState');
    api.setApiToken(undefined);
  },
  [MutationTypes.SET_USUARIO](state: State, payload: Usuario) {
    state.usuario = payload;
  },
  [MutationTypes.DELETE_USUARIO](state: State) {
    state.usuario = undefined;
  },
  [MutationTypes.SET_SUMINISTROS](state: State, payload: Suministro[]) {
    state.suministros = payload;
  },
  [MutationTypes.SET_REQUERIMIENTOS_CATEGORIAS](state: State, payload: RequerimientoCategoria[]) {
    state.requerimientosCategorias = payload;
  },
  [MutationTypes.SET_TARJETAS](state: State, payload: Tarjeta[]) {
    state.tarjetas = payload;
  },
  [MutationTypes.ADD_TARJETA](state: State, payload: Tarjeta) {
    state.tarjetas.unshift(payload);
  },
}