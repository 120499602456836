import ApiToken from './ApiToken';
import Suministro from './Suministro';
import Usuario from './Usuario';
import RequerimientoCategoria from './RequerimientoCategoria';

const RERQUERIMIENTOS_CATEGORIAS_INICIAL = [
  {
    "id": 1,
    "nombre": "Alumbrado público",
    "textoAyuda": "",
    "sustantivo": "reclamo",
    "sustantivoPlural": "reclamos",
    "adjuntarFotos": 1,
    "adjuntarFotosObs": undefined,
    "adjuntarArchivos": 0,
    "adjuntarArchivosObs": undefined,
    "adjuntarUbicacion": 1
  },
  {
    "id": 2,
    "nombre": "Denuncia de artefacto quemado",
    "textoAyuda": "",
    "sustantivo": "reclamo",
    "sustantivoPlural": "reclamos",
    "adjuntarFotos": 2,
    "adjuntarFotosObs": "Por favor adjuntá una fotografía del artefacto.",
    "adjuntarArchivos": 1,
    "adjuntarArchivosObs": "Podés adjuntar cualquier documentación que pudiera ser de interés, como la factura de compra o un informe técnico.",
    "adjuntarUbicacion": 0
  },
  {
    "id": 3,
    "nombre": "Otros reclamos",
    "textoAyuda": "",
    "sustantivo": "reclamo",
    "sustantivoPlural": "reclamos",
    "adjuntarFotos": 1,
    "adjuntarFotosObs": undefined,
    "adjuntarArchivos": 1,
    "adjuntarArchivosObs": undefined,
    "adjuntarUbicacion": 1
  },
  {
    "id": 4,
    "nombre": "Cambio de titularidad",
    "textoAyuda": "",
    "sustantivo": "trámite",
    "sustantivoPlural": "trámites",
    "adjuntarFotos": 0,
    "adjuntarFotosObs": undefined,
    "adjuntarArchivos": 2,
    "adjuntarArchivosObs": "Adjuntá la documentación requerida.",
    "adjuntarUbicacion": 0
  },
  {
    "id": 5,
    "nombre": "Alta débito automático",
    "textoAyuda": "",
    "sustantivo": "trámite",
    "sustantivoPlural": "trámites",
    "adjuntarFotos": 0,
    "adjuntarFotosObs": undefined,
    "adjuntarArchivos": 2,
    "adjuntarArchivosObs": "Adjuntá la documentación requerida.",
    "adjuntarUbicacion": 0
  },
  {
    "id": 6,
    "nombre": "Adhesión del familiar al servicio solidario de sepelio",
    "textoAyuda": "",
    "sustantivo": "trámite",
    "sustantivoPlural": "trámites",
    "adjuntarFotos": 0,
    "adjuntarFotosObs": undefined,
    "adjuntarArchivos": 2,
    "adjuntarArchivosObs": "Adjuntá la documentación requerida.",
    "adjuntarUbicacion": 0
  }
];

export default class PersistentState {
  public apiToken: ApiToken | undefined = undefined;
  public usuario: Usuario | undefined = undefined;
  public suministros: Suministro[] = [];
  public requerimientosCategorias: RequerimientoCategoria[] = RequerimientoCategoria.fromDataArray(RERQUERIMIENTOS_CATEGORIAS_INICIAL);

  static fromData(data: any): PersistentState {
    const res = new PersistentState()

    res.apiToken = ApiToken.fromData(data.apiToken);
    res.usuario = Usuario.fromData(data.usuario);
    res.suministros = Suministro.fromDataArray(data.suministros);
    res.requerimientosCategorias = RequerimientoCategoria.fromDataArray(data.requerimientosCategorias);

    return res;
  }
}