import Suministro from './Suministro';

export default class SuministroMail {
  constructor(
    public id: number,
    public suministro: Suministro | string | number,
    public email: string,
    public validado: boolean = false,
    public desuscribir: boolean = false,
    public ultimaFactura: number = 0,
  ) {
  }

  static fromData(data: any): SuministroMail {
    return new SuministroMail(
      data.id,
      data.suministro,
      data.email,
      data.validado,
      data.desuscribir,
      data.ultimaFactura,
    );
  }

  static fromDataArray(data: any): SuministroMail[] {
    const res = new Array<SuministroMail>();
    data.forEach(function (el: any) {
      res.push(SuministroMail.fromData(el));
    });
    return res;
  }

  get Iri(): string {
    return '/api/suministro_mails/' + this.id;
  }

  get suscribir(): boolean {
    return !this.desuscribir;
  }

  set suscribir(value: boolean) {
    this.desuscribir = !value;
  }
}