export enum ActionTypes {
  INIT = 'INIT',
  LOGOUT = 'LOGOUT',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  LOGIN = 'LOGIN',
  AUTOLOGIN = 'AUTOLOGIN',
  REFRESH_USUARIO = 'REFRESH_USUARIO',
  REFRESH_REQUERIMIENTOS_CATEGORIAS = 'REFRESH_REQUERIMIENTOS_CATEGORIAS',
  REFRESH_TARJETAS = 'REFRESH_TARJETAS',
  REFRESH_SUMINISTROS = 'REFRESH_SUMINISTROS',
  DEL_USUARIOSUMINISTRO = 'DEL_USUARIOSUMINISTRO',
  ADD_TARJETA = 'ADD_TARJETA',
  DEL_TARJETA = 'DEL_TARJETA',
  PAGO_INICIAR = 'PAGO_INICIAR',
  PAGO_GUARDAR = 'PAGO_GUARDAR',
}
