<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Toast from 'primevue/toast';
import SideNav from '@/components/layout/SideNav.vue';
import AnonimoEncab from '@/components/AnonimoEncab.vue';
import { useStore } from '@/store';
import PersistentState from '@/model/PersistentState';
import { MutationTypes } from '@/store/mutation-types';
import { ActionTypes } from '@/store/action-types';

const cargando = ref(true);
const router = useRouter();
const route = useRoute();
const store = useStore();

const isLoggedIn = computed(() => {
  return cargando.value == false && store.getters.isLoggedIn;
});

function esEntornoProduccion(): boolean {
  return import.meta.env.VITE_USER_DEPLOYMENT_INSTANCE_TYPE == 'production';
}

function instanceType(): string {
  return import.meta.env.VITE_USER_DEPLOYMENT_INSTANCE_TYPE;
}

store.subscribe((mutation: any) => {
  if (mutation.type == MutationTypes.LOGOUT) {
    router.push('/login');
  }
});

if (localStorage.getItem('persistentState')) {
  try {
    const persistentState = PersistentState.fromData(
      JSON.parse(localStorage.getItem('persistentState')!)
    );
    store.dispatch(ActionTypes.INIT, persistentState);

    store.dispatch(ActionTypes.REFRESH_USUARIO, undefined);
    store.dispatch(ActionTypes.REFRESH_SUMINISTROS, undefined);
    store.dispatch(
      ActionTypes.REFRESH_REQUERIMIENTOS_CATEGORIAS,
      undefined
    );
  } catch {
    // probablemente hay Json inválido en localStorage
    localStorage.removeItem('inicio_facturas');
    localStorage.removeItem('persistentState');
  } finally {
    cargando.value = false;
  }
} else {
  if (route.meta?.requiresAuth) {
    router.push({
      name: 'login',
      query: { redirect: route.fullPath }
    });
  }
  cargando.value = false;
}
</script>

<template>
  <SideNav v-if="isLoggedIn" />
  <div id="page-wrapper" class="page-wrapper"
    :class="{ 'page-wrapper-testing': !esEntornoProduccion(), 'page-wrapper-production': esEntornoProduccion(), }"
    v-if="isLoggedIn">
    <div class="content-wrapper">
      <router-view />
    </div>
  </div>

  <div id="noside-wrapper" class="noside-wrapper" v-if="cargando == false && isLoggedIn == false">
    <AnonimoEncab />
    <div class="content-wrapper">
      <router-view />
    </div>
  </div>

  <div v-if="cargando == true">
    Cargando...
  </div>

  <Toast position="bottom-right" />
</template>

<style>
@import "./assets/styles.css";
</style>
