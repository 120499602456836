export default class Tarjeta {
  constructor(
    public id: number,
    public titularNombre: string,
    public numeroEnmascarado: string,
    public vencimiento: string,
    public emisor: string,
    public emisorNombre: string,
    public principal: boolean,
    public obs: string | undefined,
  ) {
  }

  ultimos4(): string {
    return this.numeroEnmascarado.substring(this.numeroEnmascarado.length - 4);
  }

  static fromData(data: any): Tarjeta {
    return new Tarjeta(
      data.id,
      data.titularNombre,
      data.numeroEnmascarado,
      data.vencimiento,
      data.emisor,
      data.emisorNombre,
      data.principal,
      data.obs,
    );
  }

  static fromDataArray(data: any): Tarjeta[] {
    const res = new Array<Tarjeta>();
    data.forEach(function (el: any) {
      res.push(Tarjeta.fromData(el));
    });
    return res;
  }

  get Iri(): string {
    return '/api/tarjetas/' + this.id;
  }

  get vencida(): boolean {
    const mesTarjeta = parseInt(this.vencimiento.substr(0, 2));
    const anyoTarjeta = parseInt(this.vencimiento.substr(2, 2));
    const mesActual = new Date().getMonth() + 0;
    const anyoActual = new Date().getFullYear() - 2000;

    return anyoTarjeta < anyoActual || (anyoTarjeta == anyoActual && mesTarjeta < mesActual);
  }
}
