export default class Suministro {
  constructor(
    public id: number,
    public nombre: string,
    public cuenta: string,
    public documento: string | undefined,
    public cuit: string | undefined,
    public pagoInhabilitado: boolean = false,
  ) {
  }

  static fromData(data: any): Suministro {
    return new Suministro(
      data.id,
      data.nombre,
      data.cuenta,
      data.documento,
      data.cuit ? data.cuit.replace('-', '') : undefined,
      data.pagoInhabilitado,
    );
  }

  static fromDataArray(data: any): Suministro[] {
    const res = new Array<Suministro>();
    data.forEach(function (el: any) {
      res.push(Suministro.fromData(el));
    });
    return res;
  }

  get Iri(): string {
    return '/api/suministros/' + this.id;
  }
}