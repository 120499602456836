export default class UsuarioPreferencias {
  constructor(
    public id: number,
    public tokenFirebase: string,
    public mailFactura: boolean,
    public mailVencimiento: boolean,
    public mailRequerimiento: boolean,
    public notifVencimiento: boolean,
    public smsVencimiento: boolean,
    public smsCorte: boolean,
    public smsOtros: boolean,
    public smsVia: string,
  ) {
  }

  static fromData(data: any): UsuarioPreferencias {
    return new UsuarioPreferencias(
      data.id,
      data.tokenFirebase,
      data.mailFactura,
      data.mailVencimiento,
      data.mailRequerimiento,
      data.notifVencimiento,
      data.smsVencimiento,
      data.smsCorte,
      data.smsOtros,
      data.smsVia,
    );
  }

  static fromDataArray(data: any): UsuarioPreferencias[] {
    const res = new Array<UsuarioPreferencias>();
    data.forEach(function (el: any) {
      res.push(UsuarioPreferencias.fromData(el));
    });
    return res;
  }

  get Iri(): string {
    return '/api/usuario_preferencias/' + this.id;
  }
}
