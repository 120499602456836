<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';

import { useRouter } from 'vue-router';
import Dialog from 'primevue/dialog';

import { MutationTypes } from '@/store/mutation-types';
import { ActionTypes } from '@/store/action-types';
import { State } from '@/store/state';
import { useStore } from '@/store';

import RequerimientoCategoria from '@/model/RequerimientoCategoria';

const router = useRouter();
const store = useStore();

const categoriasTramites = computed(() => {
  return store.state.requerimientosCategorias.filter(c => c.sustantivo == 'trámite');
});
const categoriasReclamos = computed(() => {
  return store.state.requerimientosCategorias.filter(c => c.sustantivo == 'reclamo');
});

// Indica que el menú de navegación está permanentemente visible (por ejemplo en pantallas grandes)
const sideNavFixedVisible = ref(false);
// Indica que el menú está temporalmente visible o invisible (el usuario hizo clic en el botón hamburguesa para mostrar el menú)
const sideNavTempVisible = ref(false);

// Indica si el menú está deshabilitado
const sideNavDisabled = ref(false);

const logoutDialogVisible = ref(false);

// Ignorar el próximo clic. Al mostrar temporalmente el menú, cualquier clic lo desactiva, excepto el mismo clic
// que lo activó. Por eso es necesario ignorar el próximo evento clic al mostrar el menú.
let ignoreNextClick = false;

function onResize() {
  if (window.innerWidth > 768) {
    // En pantallas anchas, el sidenav aparece visible de forma permanente
    sideNavFixedVisible.value = true;
    updateSideNavVisible();
  } else {
    // En pantallas estrechas, está oculto permanente (pero puede estar visible temporalmente)
    sideNavFixedVisible.value = false;
    updateSideNavVisible();
  }
}

const isLoggedIn = computed((): boolean => {
  return store.getters.isLoggedIn;
});

const menuAdmin = computed(() => {
  return (
    isAdmin.value ||
    hasRole('ROLE_REQUERIMIENTO_ENCARGADO') ||
    hasRole('ROLE_REQUERIMIENTO_ADMIN') ||
    hasRole('ROLE_SUMINISTRO_MAIL')
  );
});

const isAdmin = computed(() => {
  return store.getters.isAdmin;
});

const isConfirmado = computed(() => {
  return store.getters.isConfirmado;
});

const habilitarPagosVer = computed((): boolean => {
  return JSON.parse(import.meta.env.VITE_APP_PAGOS_VER ?? 'false');
});

function hasRole(role: string): boolean {
  return (
    isLoggedIn.value &&
    store.getters.usuario != undefined &&
    store.getters.usuario.hasRole(role)
  );
}

function logout() {
  logoutDialogVisible.value = false;
  store.dispatch(ActionTypes.LOGOUT, undefined).finally(() => {
    router.push('/');
  });
}

function showLogoutDialog() {
  logoutDialogVisible.value = true;
}

function globalClick() {
  //console.log('globalClick ' + this.ignoreNextClick);
  if (ignoreNextClick === false) {
    hideSideNav();
  }
  ignoreNextClick = false;
}

function hideSideNav() {
  if (sideNavTempVisible.value) {
    sideNavTempVisible.value = false;
    updateSideNavVisible();
  }
}

function showSideNav() {
  ignoreNextClick = true;
  if (false == sideNavTempVisible.value) {
    sideNavTempVisible.value = true;
    updateSideNavVisible();
  }
}

function toggleSideNavTempVisible() {
  sideNavTempVisible.value = !sideNavTempVisible.value;
  updateSideNavVisible();
}

function updateSideNavVisible() {
  const elBurguer = document.getElementById('burguer-button')!;
  const elSideNav = document.getElementById('sidenav');
  const elPageWrapper = document.getElementById('page-wrapper')!;

  if (elSideNav == null) {
    return;
  }

  if (sideNavDisabled.value) {
    elSideNav.classList.add('disabled');
    elSideNav.setAttribute('disabled', 'disabled');
  } else {
    elSideNav.classList.remove('disabled');
    elSideNav.removeAttribute('disabled');
  }

  if (sideNavFixedVisible.value || sideNavTempVisible.value) {
    //document.getElementById('burguer-button')!.classList.toggle('change');
    elBurguer.style.display = 'none';
    elSideNav.classList.add('sidenav-visible');
    elSideNav.classList.remove('sidenav-hidden');
    elPageWrapper.classList.add('sidenav-visible');
    elPageWrapper.classList.remove('sidenav-hidden');
  } else {
    elBurguer.style.display = sideNavDisabled.value ? 'none' : 'block';
    elSideNav.classList.remove('sidenav-visible');
    elSideNav.classList.add('sidenav-hidden');
    elPageWrapper.classList.remove('sidenav-visible');
    elPageWrapper.classList.add('sidenav-hidden');
  }
}

store.subscribe((mutation: any, state: State) => {
  if (mutation.type == MutationTypes.SET_SIDENAV_DISABLED) {
    sideNavDisabled.value = state.sideNav.disabled;
    updateSideNavVisible();
  }
});

window.addEventListener('resize', onResize);
document.addEventListener('click', globalClick);

onMounted(() => {
  onResize();
});
</script>

<template>
  <div class="burguer-button" id="burguer-button" @click="showSideNav">
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
  </div>

  <div id="sidenav" class="sidenav">
    <div class="logo-container">
      <img src="/img/cooprg21_oficinavirtual_horizontal_blanco.svg" alt="logo" class="logo" />
    </div>

    <a href="javascript:void(0)" v-if="!sideNavFixedVisible" class="closebtn"
      @click="toggleSideNavTempVisible">&times;</a>

    <router-link class="menu-option" active-class="active" to="/">Inicio</router-link>
    <router-link v-if="isConfirmado" class="menu-option" active-class="active" to="/facturas">Facturas y pagos
    </router-link>
    <div class="submenu" v-if="isConfirmado">
      <span class="submenu-title">Trámites</span>

      <router-link v-for="categ in categoriasTramites" class="menu-option" active-class="active" :to="{
        name: 'requerimientos',
        params: { catid: categ.id },
      }">{{ categ.nombre }}</router-link>
    </div>
    <div class="submenu" v-if="isConfirmado">
      <span class="submenu-title">Reclamos</span>
      <router-link v-for="categ in categoriasReclamos" class="menu-option" active-class="active" :to="{
        name: 'requerimientos',
        params: { catid: categ.id },
      }">{{ categ.nombre }}</router-link>
    </div>
    <router-link v-if="isConfirmado" class="menu-option" active-class="active" to="/consumos">Consumos</router-link>

    <div class="submenu" v-if="menuAdmin">
      <span class="submenu-title">Admin</span>
      <router-link v-if="hasRole('ROLE_REQUERIMIENTO_ADMIN') ||
        hasRole('ROLE_REQUERIMIENTO_ENCARGADO')
      " class="menu-option" active-class="active" to="/admin/requerimientos">Requerimientos</router-link>
      <router-link v-if="isAdmin ||
        hasRole('ROLE_USUARIO_ADMIN') ||
        hasRole('ROLE_REQUERIMIENTO_ADMIN') ||
        hasRole('ROLE_REQUERIMIENTO_ENCARGADO')
      " class="menu-option" active-class="active" to="/admin/usuarios">Usuarios</router-link>
      <router-link v-if="isAdmin || hasRole('ROLE_SUMINISTRO_MAIL')" class="menu-option" active-class="active"
        to="/admin/suministro_mails">Correos</router-link>
      <router-link v-if="isAdmin" class="menu-option" active-class="active" to="/admin/facturas/pagos">Pagos
      </router-link>
      <router-link v-if="isAdmin" class="menu-option" active-class="active" to="/admin/mensajes">Mensajes salientes
      </router-link>
      <router-link v-if="isAdmin" class="menu-option" active-class="active" to="/admin/archivos_facturas">Archivos
      </router-link>
      <router-link v-if="isAdmin" class="menu-option" active-class="active" to="/admin/archivos_subidas">Archivos v2
      </router-link>
      <router-link v-if="hasRole('ROLE_CORTE_ADMIN')" class="menu-option" active-class="active"
        to="/admin/cortes">Cortes</router-link>
      <router-link v-if="hasRole('ROLE_ADMIN')" class="menu-option" active-class="active"
        to="/admin/indicadores">Indicadores</router-link>
    </div>

    <div class="submenu" v-if="isLoggedIn">
      <span class="submenu-title">Usuario</span>
      <router-link v-if="isLoggedIn" class="menu-option" active-class="active" to="/perfil">Perfil</router-link>
      <router-link v-if="isLoggedIn" class="menu-option" active-class="active" to="/preferencias">Ajustes</router-link>
      <router-link v-if="isAdmin || hasRole('ROLE_REQUERIMIENTO_ENCARGADO')" class="menu-option" active-class="active"
        to="/2fa">2FA</router-link>
      <router-link v-if="isConfirmado && habilitarPagosVer" class="menu-option" active-class="active"
        to="/facturas/pagos">Historial de pagos</router-link>
      <router-link v-if="isConfirmado" class="menu-option" active-class="active" to="/suministros">Suministros
      </router-link>

      <router-link v-if="isAdmin && isConfirmado && habilitarPagosVer" class="menu-option" active-class="active"
        to="/tarjetas">Medios de pago</router-link>

      <router-link v-if="!isLoggedIn" class="menu-option" active-class="active" to="/login">Ingresar</router-link>
      <router-link v-if="!isLoggedIn" class="menu-option" active-class="active" to="/registro">Registrarse</router-link>
      <a class="menu-option" v-if="isLoggedIn" @click="showLogoutDialog">Cerrar sesión</a>
    </div>

    <div class="menu-bottom-links">
      <a href="https://play.google.com/store/apps/details?id=com.cooprg.oficinavirtual" target="_blank">
        <img src="/img/disponible-en-google-play.svg" style="width: 120px" />
      </a>
      <a href="https://apps.apple.com/us/app/coop-el%C3%A9ctrica-de-r%C3%ADo-grande/id1582302444" target="_blank">
        <img src="/img/descargalo-en-app-store.svg" style="width: 120px" />
      </a>
    </div>
  </div>

  <Dialog v-model:visible="logoutDialogVisible">
    <template #header>
      <h3>Cerrar sesión</h3>
    </template>
    ¿Estás seguro de cerrar tu sesión en la Oficina Virtual?
    <template #footer>
      <button class="p-button p-button-success" @click="logout">
        <i class="fa fa-check"></i>&nbsp; Salir
      </button>
    </template>
  </Dialog>
</template>

<style>
#sidenav .logo-container {
  width: 100%;
  margin-bottom: 16px;
  background-color: #2f7a4f;
}

#sidenav .logo {
  max-width: 310px;
  width: 100%;
  padding: 16px;
}

/* Position and style the close button (top right corner) */

#sidenav .closebtn {
  position: absolute;
  top: 90px;
  right: 8px;
  font-size: 24px;
  margin-left: 50px;
  color: #888888;
}

#sidenav .closebtn:hover {
  color: #222222;
}

#burguer-button {
  position: fixed;
  top: 64px;
  left: -12px;
  box-shadow: 0px 0px 4px #888888;
  display: inline-block;
  cursor: pointer;
  --border: 1px solid silver;
  border-radius: 5px;
  padding: 6px 6px;
  z-index: 999999998;
  opacity: 0.75;
}

#burguer-button .bar1,
#burguer-button .bar2,
#burguer-button .bar3 {
  width: 24px;
  height: 3px;
  background-color: rgb(92, 92, 92);
  margin: 5px 0;
  transition: 0.33s;
}


/* Position and style the close button (top right corner) */

#sidenav .closebtn {
  position: absolute;
  top: 90px;
  right: 8px;
  font-size: 24px;
  margin-left: 50px;
  color: #888888;
}

#sidenav .closebtn:hover {
  color: #222222;
}

#burguer-button {
  position: fixed;
  top: 64px;
  left: -12px;
  box-shadow: 0px 0px 4px #888888;
  display: inline-block;
  cursor: pointer;
  --border: 1px solid silver;
  border-radius: 5px;
  padding: 6px 6px;
  z-index: 999999998;
  opacity: 0.75;
}

#burguer-button .bar1,
#burguer-button .bar2,
#burguer-button .bar3 {
  width: 24px;
  height: 3px;
  background-color: rgb(92, 92, 92);
  margin: 5px 0;
  transition: 0.33s;
  border-radius: 3px;
}

#sidenav.sidenav-hidden {
  width: 280px;
  left: -280px;
  transition: 0.25s;
}

#sidenav.sidenav-visible {
  width: 280px;
  left: 0;
  transition: 0.25s;
  box-shadow: 5px 0px 15px #888888;
}

/* The side navigation menu */

#sidenav {
  height: 100%;
  width: 280px;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: -280px;
  background-color: #f1f1ef;
  overflow-x: hidden;
  transition: 0.33s;
  font-size: 16px;
}

#sidenav .submenu {
  margin-left: 14px;
  font-size: 12px;
}

#sidenav .submenu-title {
  margin-left: -14px;
  font-size: 14px;
  color: #4e4e4e;
  padding: 8px 8px 8px 32px;
  border-bottom: 1px dotter silver;
  display: block;
  transition: 0.33s;
  font-weight: 600;
}

/* The navigation menu links */

#sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-weight: 600;
  color: #181a17;
  display: block;
  transition: 0.33s;
  cursor: pointer;
}

#sidenav .menu-bottom-links {
  padding: 16px 0px;
  border: none;
  border-bottom: none;
}

#sidenav .menu-bottom-links.a {
  border: none;
  border-bottom: none;
}

/* When you mouse over the navigation links, change their color */

#sidenav a.menu-option:hover {
  color: #222222;
  background-color: #aeaeae;
}

/* When you mouse over the navigation links, change their color */

#sidenav a.menu-option.active {
  padding-left: 36px;
  background-color: #cecece;
  color: #2f7a4f;
}

/* En pantallas menos altas, achicar las entradas del menú */

@media screen and (max-height: 450px) {
  #sidenav a.menu-option {
    font-size: 14px;
  }
}
</style>