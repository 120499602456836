export default class RowCollection<ModelType> {
    constructor(
        public rows: ModelType[] = [],
        public totalItems: number = 0,
    ) { }

    public push(row: ModelType): void {
        this.rows.push(row);
    }

    public get count(): number {
        return this.rows.length;
    }
}