import PersistentState from '@/model/PersistentState';
import VolatileState from '@/model/VolatileState';
import ApiToken from '@/model/ApiToken';
import Usuario from '@/model/Usuario';
import Suministro from '@/model/Suministro';
import Tarjeta from '@/model/Tarjeta';
import Pago from '@/model/Pago';
import RequerimientoCategoria from '@/model/RequerimientoCategoria';

export class State {
  persistentState: PersistentState = new PersistentState();
  volatileState: VolatileState = new VolatileState();

  get sideNav(): any {
    return this.volatileState.sideNav;
  }

  get usuario(): Usuario | undefined {
    return this.persistentState.usuario;
  }

  set usuario(usuario: Usuario | undefined) {
    this.persistentState.usuario = usuario;
    this.persistState();
  }

  get apiToken(): ApiToken | undefined {
    return this.persistentState.apiToken;
  }

  set apiToken(apiToken: ApiToken | undefined) {
    this.persistentState.apiToken = apiToken;
    this.persistState();
  }

  get suministros(): Suministro[] {
    return this.persistentState.suministros;
  }

  set suministros(suministros: Suministro[]) {
    this.persistentState.suministros = suministros;
    this.persistState();
  }

  get suministroSeleccionado(): Suministro | undefined {
    return this.volatileState.suministroSeleccionado;
  }

  set suministroSeleccionado(suministroSeleccionado: Suministro | undefined) {
    this.volatileState.suministroSeleccionado = suministroSeleccionado;
  }

  public getSuministroPorIdOIri(id: number | string): Suministro | undefined {
    return this.suministros.find(s => s.id == id || '/api/suministros/' + s.id === id);
  }

  get requerimientosCategorias(): RequerimientoCategoria[] {
    return this.persistentState.requerimientosCategorias;
  }

  set requerimientosCategorias(requerimientosCategorias: RequerimientoCategoria[]) {
    this.persistentState.requerimientosCategorias = requerimientosCategorias;
    this.persistState();
  }

  get tarjetas(): Tarjeta[] {
    return this.volatileState.tarjetas;
  }

  set tarjetas(tarjetas: Tarjeta[]) {
    this.volatileState.tarjetas = tarjetas;
  }

  get pago(): Pago | undefined {
    return this.volatileState.pago;
  }

  set pago(pago: Pago | undefined) {
    this.volatileState.pago = pago;
  }

  /**
   * Guarda el estado persistente en localStorage.
   */
  private persistState() {
    localStorage.setItem('persistentState', JSON.stringify(this.persistentState));
  }
}

export const state = new State();
