export default class UsuarioSuministro {
  constructor(
    public suministro: string,
    public documento: string,
  ) {
  }

  static fromData(data: any): UsuarioSuministro {
    return new UsuarioSuministro(
      data.suministro,
      data.documento,
    );
  }
}
