export default class Consumo {
  constructor(
    public id: number,
    public suministro: string,
    public periodo: string,
    public importe: number,
    public consumo: number,
  ) {
  }

  static fromData(data: any): Consumo {
    return new Consumo(
      data.id,
      data.suministro,
      data.periodo,
      data.importe,
      data.consumo,
    );
  }

  static fromDataArray(data: any): Consumo[] {
    const res = new Array<Consumo>();
    data.forEach(function (el: any) {
      res.push(Consumo.fromData(el));
    });
    return res;
  }
}