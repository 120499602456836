import Usuario from './Usuario';

export default class ApiToken {
  constructor(
    public token: string,
    public usuarioId: number,
    public usuario: Usuario | undefined = undefined,
  ) { }

  static fromData(data: any): ApiToken {
    return new ApiToken(
      data.token,
      data.usuarioId,
      data.usuario ? Usuario.fromData(data.usuario) : undefined
    );
  }
}
